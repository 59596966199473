<template>
  <div>
    <v-card width="500px" style="background-color: var(--v-greyRaised-base) !important">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>Cargo Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="red" icon v-if="chosenProduct.id" @click="confirmDelete = true"><v-icon>delete</v-icon></v-btn>
        <v-btn color="secondary" icon :disabled="!chosenProduct.product ||
          !chosenProduct.hsCode ||
          !chosenProduct.description
          " @click="confirmProduct"><v-icon>save</v-icon></v-btn>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-select hide-details class="my-2" label="Type" :items="['Product Group', 'Product', 'Variety']"
              v-model="filterProductType" outlined dense></v-select>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-autocomplete class="my-2" hide-details ref="countrySelect" hide-no-data hide-selected label="Product" dense
              outlined :items="filterProducts" clearable item-text="name" item-value="id"
              :menu-props="{ closeOnContentClick: true }" @change="setCargoItemDetails" v-model="chosenProduct.product"
              return-object :loading="loadingProduct">
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.type }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="data.item.parentProduct" style="font-size: 12px">
                    Parent: {{ data.item.parentProduct.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-textarea hide-details class="my-2" rows="3" label="Description" v-model="chosenProduct.description"
              outlined></v-textarea>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="HS Code" v-model="chosenProduct.hsCode" outlined
              dense></v-text-field>
          </v-col>
      

          
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="No Pallets" type="number" v-model="chosenProduct.pallets"
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="Nett Weight" suffix="Kg" v-model="chosenProduct.nettWeight"
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="Gross Weight" suffix="Kg" v-model="chosenProduct.grossWeight"
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="Cubic measure" suffix="CBM" v-model="chosenProduct.volume"
              outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" label="Batch Number" v-model="chosenProduct.batchNumber" outlined
              dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-select hide-details class="my-2" label="Quantity Type" v-model="selectedQuantityType" outlined dense clearable
              :items="productQuantityTypes" @change="selectQuantityType()" return-object></v-select>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field hide-details class="my-2" :label="`Quantity`"
              v-model="chosenProduct.quantity" outlined dense></v-text-field>
          </v-col>
         
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-switch label="Industrial Use" dense v-model="chosenProduct.industrial" hide-details></v-switch>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-switch label="Organic" dense v-model="chosenProduct.organic" hide-details></v-switch>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch label="Hazardous" dense v-model="chosenProduct.hazardous" hide-details></v-switch>
            </v-col>
        </v-row>
        <v-list dense subheader v-if="chosenProduct.hazardous">
          <v-divider></v-divider>
          <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0" v-if="chosenProduct.hazardous">
            <v-text-field hide-details class="my-2"  label="UN Code" v-model="chosenProduct.unCode" outlined
              dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0" v-if="chosenProduct.hazardous">
            <v-text-field hide-details class="my-2"  label="UN Packing Group" v-model="chosenProduct.unCodePackingGroup" outlined
              dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0" v-if="chosenProduct.hazardous">
            <v-text-field hide-details class="my-2" :label="`Hazard Class`"
              v-model="chosenProduct.class" outlined dense></v-text-field>
          </v-col>
          </v-row>
         
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmDelete" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmFileCreate = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to delete this product?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="(confirmDelete = false)">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="deleteProduct()" :loading="loadingDelete">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<!-- 
EVENTS:
@close -> closes dialog
@chosenProduct -> returns chosen product as {product, description, hsCode, weight, volume}

-->

<script>
export default {
  props: ['item'],
  data: () => ({
    cargoKey: 0,
    confirmDelete: false,
    filterProductType: 'Product',
    products: [],
    loadingDelete: false,
    loadingProduct: false,
    chosenProduct: {},
    selectedQuantityType: { value: '', text: '' },
    productQuantityTypes: [
      { value: 'BG', text: 'Bag' },
      { value: 'TT', text: 'Bag, tote' },
      { value: 'BA', text: 'Barrel' },
      { value: "BL", text: "Bale" },
      { value: 'BI', text: 'Bin'},
      { value: 'BX', text: 'Box' },
      { value: 'VL', text: 'Bulk, liquid' },
      { value: 'VR', text: 'Bulk, solid' },
      { value: 'BH', text: 'Bunch' },
      { value: 'BE', text: 'Bundle' },
      { value: 'CT', text: 'Carton' },
      { value: 'CS', text: 'Case' },
      { value: 'CN', text: 'Container' },
      { value: 'CR', text: 'Crate' },
      { value: 'DR', text: 'Drum' },
      { value: 'FB', text: 'Flexibag' },
      { value: 'JT', text: 'Jutebag' },
      { value: 'LU', text: 'Lug' },
      { value: 'PK', text: 'Package' },
      { value: 'PA', text: 'Packet' },
      { value: 'PX', text: 'Pallet' },
      { value: 'PT', text: 'Pot' },
      { value: 'PO', text: 'Pouch' },
      { value: 'RO', text: 'Roll' },
      { value: 'SH', text: 'Sachet' },
      { value: 'SA', text: 'Sack' },
      { value: 'SI', text: 'Skid' },
      { value: 'PU', text: 'Tray' },
      { value: 'VI', text: 'Vial' }
    ]
  }),
  watch: {
    'item.id': {
      immediate: true,
      handler(val) {
        if (val) {
          this.chosenProduct = {
            id: this.item.id,
            volume: this.item.volume,
            nettWeight: this.item.nettWeight,
            grossWeight: this.item.grossWeight,
            quantity: this.item.quantity,
            hsCode: this.item.hsCode,
            unCode: this.item.unCode,
            description: this.item.description,
            pallets: this.item.pallets,
            organic: this.item.organic,
            industrial: this.item.industrial,
            hazardous: this.item.hazardous,
            quantityType: this.item.quantityType,
            quantityTypeCode: this.item.quantityTypeCode,
            batchNumber: this.item.batchNumber,
            class: this.item.class,
            unCodePackingGroup: this.item.unCodePackingGroup
          }

          this.selectedQuantityType.value = this.chosenProduct.quantityTypeCode
          this.selectedQuantityType.text = this.chosenProduct.quantityType
        } else {
          this.chosenProduct = {...this.item}
          this.selectedQuantityType.value = 'CT'
          this.selectedQuantityType.text = 'Carton'
        }
      }
    },
  },
  async created() {
    await this.loadProducts();
  },
  beforeDestroy() {
    // this.item = null
  },
  // mounted(){
  //   console.log(this.item)
  //   if(this.item){
  //     this.chosenProduct = this.item;
  //   }
  //   
  // },
  computed: {
    filterProducts() {
      let result = []
      if (this.products.data) {
        result = this.products.data.filter((x) => x.hsCode);
        if (result && this.filterProductType) {
          result = result.filter((x) => x.type == this.filterProductType);
        }
      }
      return result;
    },
  },

  methods: {
    selectQuantityType() {
      this.chosenProduct.quantityTypeCode = this.selectedQuantityType.value
      this.chosenProduct.quantityType = this.selectedQuantityType.text
    },
    setCargoItemDetails() {
      if (this.chosenProduct.product) {
        this.chosenProduct.description = this.chosenProduct.product.hsCode.explanation;
        this.chosenProduct.hsCode = this.chosenProduct.product.hsCode.alternateCode.replace(
          / /g,
          ""
        );
      }
    },
    async deleteProduct() {
      this.loadingDelete = true
      if(!this.item.shipmentId){
        await this.$API.updateContainerProduct({
        id: this.chosenProduct.id,
        isActive: false,
        isDeleted: true
      })
      } else {
        await this.$API.updateShipmentContainerProduct({
        id: this.chosenProduct.id,
        isActive: false,
        isDeleted: true
      })
      }
      
      this.loadingDelete = false
      this.confirmDelete = false
      this.$emit('deleted')
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      if (this.item && this.item.product) {
        this.chosenProduct.product = this.products.data.find(x => x.id == this.item.productId)
        // console.log(this.chosenProduct.product)
      }
      this.loadingProduct = false;
    },

    confirmProduct() {
      this.chosenProduct.type = this.filterProductType.toUpperCase();
      if(this.chosenProduct.product){
        this.chosenProduct.productId = this.chosenProduct.product.id
      }
      this.$emit('chosenProduct', this.chosenProduct);
      this.chosenProduct = {};
      this.loadingProduct = false;
    },
  }
}



</script>